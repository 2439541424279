import React from 'react'
import { ResolverWindowListToolbarOptions } from '@newageerp/crm-ui'
import ToolbarButton from '../../../PackageDistinctColumn/Toolbar'

export default function Resolver(props: ResolverWindowListToolbarOptions) {
    return (
        <>
            <ToolbarButton dataSource={props.dataSource} />
        </>
    )
}
