import Dashboard from "./Dashboard/Dashboard";
import DashboardEarlyFraudWarning from "./Dashboard/DashboardEarlyFraudWarning";
import { CrmConfigSchema } from "./ConfigSchema/CrmConfigSchema";
import { StripeConfigSchema } from "./ConfigSchema/StripeConfigSchema";
import BalanceHistoryReportImport from "./BalanceHistory/Import/BalanceHistoryReportImport";
import UTest, { MpInfoWrapper } from "./UTest/UTest";
import { UniRoColumn } from "./Bit/UniRoColumn";

export const PluginsMap: any = {
    'bit-UniRoColumn': UniRoColumn,

    'custom.UProvider': MpInfoWrapper,
    
    "custom.routes": {
        '/c/dashboard/main': Dashboard,
        '/c/dashboard/early-fraud': DashboardEarlyFraudWarning,
        '/c/balance-history/import': BalanceHistoryReportImport,

        '/c/test/:schema/:type': UTest,
    },
    config: {
        stripe: StripeConfigSchema,
        crm: CrmConfigSchema,
    }
}