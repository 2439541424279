import React, { Fragment, useEffect, useState } from 'react'
import { Window, Button, Tabs, Card, Table } from '@newageerp/crm-ui'
import { axiosInstance } from '@newageerp/v3.bundles.utils-bundle';
import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';

type Props = {
  dataSource: string
}

type Property = {
  title: string,
  type: string,
  key: string,
}

export default function ToolbarButton(props: Props) {
  const [showPopup, setShowPopup] = useState(false);
  const [properties, setProperties] = useState<Property[]>([]);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  const getProperties = async () => {
    const res = await axiosInstance.post(
      '/app/nae-core/config-properties/list',
      {
        schema: props.dataSource,
        db: true,
      }
    );
    setProperties(res.data.data);
  }
  useEffect(() => {
    if (showPopup && properties.length === 0) {
      getProperties().catch(console.error)
    }
  }, [showPopup, properties]);

  useEffect(() => {
    if (selectedColumn) setSelectedTab(1);
  }, [selectedColumn])

  return (
    <Fragment>
      <Button.ToolbarButton title='Distinct column values' iconName='line-columns' onClick={() => setShowPopup(true)} />
      <Window.Popup title='Distinct column values' isPopup={showPopup} onClick={() => setShowPopup(false)} className='w-[500px]'>
        <Tabs.Default
          control={{
            get: selectedTab,
            set: setSelectedTab
          }}
          style={{
            tabPanel: {
              padding: 'pb-0'
            }
          }}
          tabs={[
            {
              tab: {
                title: 'Select column'
              },
              content: <PropertiesList properties={properties} select={setSelectedColumn} />
            },
            {
              tab: {
                title: 'Result',
                disabled: !selectedColumn,
              },

              content: <DistinctList dataSource={props.dataSource} field={selectedColumn} />
            }
          ]}
        />
      </Window.Popup>
    </Fragment>
  )
}

type PropertiesProps = {
  properties: Property[],
  select: (v: string) => void,
}
const PropertiesList = (props: PropertiesProps) => {
  const tbl = Table.small
  return <Card.WhiteCard>
    <tbl.Table
      thead={
        <thead>
          <tr>
            <tbl.Th>Field</tbl.Th>
          </tr>
        </thead>
      }
      tbody={
        <tbody>
          {props.properties.sort((a, b) => a.title.localeCompare(b.title)).map((p, pIdx) => (
            <tr key={`p-${pIdx}`}>
              <tbl.Td props={{
                className: 'cursor-pointer',
                onClick: () => props.select(p.key)
              }}>
                {p.title}
              </tbl.Td>
            </tr>
          ))}
        </tbody>
      }
    />
  </Card.WhiteCard>
}

type DistinctListProps = {
  dataSource: string,
  field: string,
}

const DistinctList = (props: DistinctListProps) => {
  const [data, setData] = useState<{ value: string }[]>([]);
  const ls = useListDataSource();

  const getData = async () => {
    const res = await axiosInstance.post(`/app/nae-core/u/getListDistinctColumn/${props.dataSource}`, { field: props.field, filters: ls.filter.prepare() });
    setData(res.data.data);
  }
  useEffect(() => {
    if (props.field) {
      getData().catch(console.error);
    }
  }, [props.field])

  const tbl = Table.small
  return <Card.WhiteCard>
    <p>Distinct values for `{props.field}` column</p>
    <tbl.Table
      thead={
        <thead>
          <tr>
            <tbl.Th>Field</tbl.Th>
          </tr>
        </thead>
      }
      tbody={
        <tbody>
          {data.sort((a, b) => a.value.localeCompare(b.value)).map((p, pIdx) => (
            <tr key={`p-${pIdx}`}>
              <tbl.Td>
                {p.value}
              </tbl.Td>
            </tr>
          ))}
        </tbody>
      }
    />
  </Card.WhiteCard>
}