import z from 'zod'

const dfFields = [
    "_viewTitle",
    "id",
    "scopes",
    "badges"
] as const;

export const DataCacheSchema: {
        [key: string]: z.ZodEnum<[string, ...string[]]>
    } = {
    'front-order-log': z.enum([...dfFields]),
    'recurring': z.enum([...dfFields]),
    'stripe-charge': z.enum([...dfFields]),
    'stripe-dispute': z.enum([...dfFields]),
    'stripe-early-fraud-warning': z.enum([...dfFields]),
    'stripe-refund': z.enum([...dfFields]),
    'stripe-webhook': z.enum([...dfFields]),
    'webhook': z.enum([...dfFields]),
}