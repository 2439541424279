import React from 'react'
import { MP } from '@newageerp/crm-ui'
import { useDataCache } from '@newageerp/v3.app.data-cache-provider';
import { TemplatesLoader, useParams } from '@newageerp/v3.templates.templates-core';

export default function UTest() {
    const { system: dsSystem } = useDataCache();
    const params = useParams<{ schema: string, type: string }>();


    const tbl = dsSystem.data.entities.find(e => e.slug === params.schema);


    return (
        <MP.GlobalProvider
            settings={{
                apiKey: dsSystem.data.settings.mp.apiKey,
                baseUrl: dsSystem.data.settings.mp.baseUrl,
            }}
        >
            <TemplatesLoader
                templateName="UTest"
                data={{
                    dataSource: `crm-stripe.${tbl?.db}`,
                    schema: params.schema,
                    type: params.type,
                }}
            />
            {/*             
        <MP.InfoProvider dataSource={`crm-stripe.${tbl?.db}`} user='all' info={{
                
                columns: [{
                    'columnType': 'int',
                    'dataType': 'int',
                    'format': 'NA',
                    'path': 'id',
                    'title': 'ID'
                }],
                order: [
                    {
                        column: 'id',
                        direction: 'DESC'
                    }
                ]
            }} 
            contentChildren={<MP.Data />} 
            /> */}
        </MP.GlobalProvider>)
}



export const MpInfoWrapper = (props: any) => {
    return (
        <MP.InfoProvider {...props} contentChildren={<MP.Data />}
        />
    )
}